/**
 * @file It contains scripts for issue add.
 */
import { mapActions, mapGetters } from "vuex"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { MAX_CHARACTER_LIMIT,  ISSUE_TYPES }from "@/constants"

export default {
  name      : "IssueAdd",
  components: {
    ThemisDateTimePicker,
    ThemisDecision,
    ThemisInput },
  data() {
    return {
      issue: {
        summary       : undefined,
        description   : undefined,
        domainId      : undefined,
        receivedAt    : undefined,
        acknowledgedAt: undefined,
        typeId        : ISSUE_TYPES[0].id
      },
      issueDescriptionCharacterLimit  : MAX_CHARACTER_LIMIT.ISSUE_DESCRIPTION,
      showAcknowledgedAtDateTimePicker: false,
      showReceivedAtDateTimePicker    : false,
      showAddIssueAlertDialog         : false
    }
  },
  computed: {
    ...mapGetters({
      domains      : "domains/domains",
      isAddingIssue: "issues/isAddingIssue",
      isIssueAdded : "issues/isIssueAdded",
      issues       : "issues/issues",
      accesses     : "accesses/accesses",
      loggedInUser : "auth/loggedInUser"
    }),
    activeDomains() {
      return this.domains?.filter(domain => domain.archived === false)
    },
    latestCreatedIssue() {
      return this.issues.sort((left, right) => new Date(right.createdAt) -
        new Date(left.createdAt))[0]
    },
    receivedAtForDisplaying() {
      if (this.issue.receivedAt) {
        return this.$moment(this.issue.receivedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    acknowledgedAtForDisplaying() {
      if (this.issue.acknowledgedAt) {
        return this.$moment(this.issue.acknowledgedAt).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    userHasAccessToTheDomain() {
      const userAccesses = this.accesses.filter(access => access.userId === this.loggedInUser.id)
      return !!userAccesses.find(access => access.criteria?.domainId ===  this.issue.domainId)
    },
    selectedDomainName() {
      if(this.issue.domainId) {
        return this.domains.find(domain => domain.id === this.issue.domainId).name
      }
    }
  },
  methods: {
    ...mapActions({
      addIssue: "issues/addIssue",
      notify  : "shared/notify"
    }),
    handleCloseReceivedAtDateTimePicker() {
      this.showReceivedAtDateTimePicker = false
    },
    handleCloseAcknowledgedAtDateTimePicker() {
      this.showAcknowledgedAtDateTimePicker = false
    },
    handleAddIssue() {
      if (this.userHasAccessToTheDomain) {
        this.addIssue(this.issue)
      } else {
        this.showAddIssueAlertDialog = true
      }
    },
    handleAddIssueAlertDialog() {
      this.addIssue(this.issue)
      this.showAddIssueAlertDialog = false
    }
  },
  watch: {
    isIssueAdded: function(newValue) {
      if (newValue) {
        if(this.userHasAccessToTheDomain) {
          this.notify({
            type      : "success",
            text      : "641",
            parameters: {
              id: this.latestCreatedIssue.id
            }
          })
          this.$router.push({ name: "issue", params: { id: this.latestCreatedIssue.id } })
        } else {

          this.notify({
            type      : "success",
            text      : "1081",
            parameters: {
              id  : this.latestCreatedIssue.id,
              name: this.selectedDomainName
            }
          })
          this.$router.push({ name: "issues" })
        }
      }
    }
  }
}
